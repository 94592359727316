exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-access-js": () => import("./../../../src/pages/about/access.js" /* webpackChunkName: "component---src-pages-about-access-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-studio-js": () => import("./../../../src/pages/about/studio.js" /* webpackChunkName: "component---src-pages-about-studio-js" */),
  "component---src-pages-about-trainer-js": () => import("./../../../src/pages/about/trainer.js" /* webpackChunkName: "component---src-pages-about-trainer-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-index-js": () => import("./../../../src/pages/course/index.js" /* webpackChunkName: "component---src-pages-course-index-js" */),
  "component---src-pages-course-personal-js": () => import("./../../../src/pages/course/personal.js" /* webpackChunkName: "component---src-pages-course-personal-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-first-js": () => import("./../../../src/pages/first.js" /* webpackChunkName: "component---src-pages-first-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-medical-save-js": () => import("./../../../src/pages/medical-save.js" /* webpackChunkName: "component---src-pages-medical-save-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */)
}

